export const environment = {
    production: true,
    api: {
        endpoint: "https://myconstituents.org/api/v1",
        identifier: "KL&S89L_HG",
        rooturl: "https://mc.osinetwork.net",
        payurl: "https://pay.sandbox.payrillagateway.com/myc",
    },
    session_keys: {
        login: "__CG_DrOLF_auth",
        reg: "__CON_TITU_reg",
    },
    maps: {
        googleMapsApiKey: "AIzaSyCHW_FisHhZyJqolyQbuAjxrb6kLe3B5d4"
    }
};
